













































import { Component, Mixins } from 'vue-property-decorator';
import { Office } from 'client-website-ts-library/types';
import { API, Config } from 'client-website-ts-library/services';
import { View } from 'client-website-ts-library/plugins';
import Form from '@/components/Forms/Form.vue';

@Component({
  components: {
    Form,
  },
})
export default class ContactUs extends Mixins(View) {
  private office: Office | null = null;

  mounted() {
    API.Offices.Get(Config.Website.Settings!.WebsiteId).then((office) => {
      this.office = office;
    });
  }
}
